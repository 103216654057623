import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import MarkerClusterGroup from 'react-leaflet-markercluster';
import L from 'leaflet';
import { services } from '../../services';
import $ from 'jquery';


export default class MapClient extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            markers: [],
            current: {}
        };
        services.getStoreLocator().then((values) => {
            this.setState({...this.state, markers: values});
        }).catch(err => {
            console.log(err);
        });
    }

    onMarkerTapped = (k) => {
        console.log('tappato ', k);
        var modal = "#modal-"+this.props.id;
        this.setState({...this.state, current: k}, () => $(modal).modal());
    }


    render() {
        return (
            <div
                className={"map-element " + this.props.cssClass}
                style={this.props.style}
            >

                <MapContainer className="markercluster-map" 
                    style={{height: this.props.content.height}} 
                    center={[this.props.content.centerLat, this.props.content.centerLng]} 
                    zoom={this.props.content.zoom} 
                    scrollWheelZoom={false}>
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />

                    <MarkerClusterGroup>
                    {this.state.markers.map(k => {
                        if (k.categories && k.categories.length > 0 && k.categories[0].icon) {
                            let myIcon = L.icon({
                                iconUrl: k.categories[0].icon,
                                iconSize: [64,64],
                                iconAnchor: [32, 64],
                                popupAnchor: null,
                                shadowUrl: null,
                                shadowSize: null,
                                shadowAnchor: null
                            });
                            return <Marker 
                                key={k.uid}
                                icon={myIcon} 
                                position={[k.lat, k.lon]}   
                                eventHandlers={{
                                    click: (e) => this.onMarkerTapped(k)
                                }}
                            ></Marker>
                        }

                        return <Marker 
                            key={k.uid} 
                            position={[k.lat, k.lon]}   
                            eventHandlers={{
                                click: (e) => this.onMarkerTapped(k)
                            }}
                        ></Marker>
                    })}
                    </MarkerClusterGroup>

                </MapContainer>
                <div className="modal fade" id={"modal-"+this.props.id} tabIndex="-1" role="dialog">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{((this.state.current.categories || [])[0] || {}).label}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <h2>{this.state.current.title}</h2>
                                {this.state.current.images && <img className="img-fluid" src={this.state.current.images[0]} />}
                                <div className="internal-description p-2">
                                    <div dangerouslySetInnerHTML={{__html: this.state.current.description}}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
