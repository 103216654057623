import React from 'react';
import { services } from '../../services';

export default class RedirectClient extends React.Component {

    get generateUrl() {
        if (this.props.content.external) {
            return this.props.content.externalLink
        }
        if ((this.props.content.link || '').indexOf('global-') === 0) {
            let bb = this.props.content.link.substring('global-'.length);
            for (let page of this.props.pages) {
                if (page.id === Number(bb)) {
                    return decodeURI(services.appendUID(page.permalink));
                }
            }
        }
        if ((this.props.content.link || '').indexOf('exp-') === 0) {
            let bb = this.props.content.link.substring('exp-'.length);
            return services.appendUID('/experience/' + bb);
        }
        return services.appendUID("?_p=" + this.props.content.link);
    }

    redirect = () => {
        setTimeout(() => {
            window.location.href = this.generateUrl;
        }, this.props.content.timeout);
    }

    render() {
        this.redirect();
        return (
            <div className={"button-element " + this.props.cssClass}></div>
        )
    }
}
