import React from 'react';



export default class CheckboxClient extends React.Component {
    render() {
        return (
            <div
                className={"checkbox-element form-group form-check "  + this.props.cssClass}
                style={this.props.style}
            >
                <input type="checkbox" className="form-check-input" 
                    id={this.props.content.name}
                    name={this.props.content.name} 
                    value={this.props.content.value}
                    required={this.props.content.required}
                    defaultChecked={this.props.content.checked}
                />
                <label className="form-check-label" 
                    htmlFor={this.props.content.name}>{this.props.content.label}</label>
            </div>
        )
    }
}
