import { UPDATE_EXPERIENCE } from '../actionTypes';
import { cleanUpByLanguage } from '../../language';

const initialState = {
    experience: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case UPDATE_EXPERIENCE:
        return {
            ...state,
            experience: cleanUpByLanguage(action.payload)
        }
    default:
      return state;
  }
}
