import React from 'react';
import { Provider } from 'react-redux'
import store from './redux/store'
import './style/style.scss';
import 'jquery/src/jquery';
import 'bootstrap/dist/js/bootstrap.js';
import 'lightbox2/dist/js/lightbox.js';
import Pages from './pages';

function App() {
  return (
    <Provider store={store}>
        <Pages />
    </Provider>
  );
}

export default App;
