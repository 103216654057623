import React from 'react';



export default class PrivacyPolicyClient extends React.Component {

    get link() {
        if (!this.props.content) {
            return '';
        }
        if (this.props.content.external) {
            return this.props.content.url;
        }
        for (let page of this.props.pages) {
            if (page.id === Number(this.props.content.link)) {
                return page.permalink;
            }
        }
        return '#' + this.props.content.link;
    }

    render() {
        return (
            <div
                className={"privacy-policy-element " +this.props.cssClass}
                style={this.props.style}
            >
                <div className="checkbox-element form-group form-check">
                <input type="checkbox" className="form-check-input" 
                    name="privacy"
                    value="true"
                    required="true"
                />
                <label className="form-check-label" 
                    htmlFor="privacy"><a href={this.link} target="_blank">{this.props.content.text}</a></label>
                </div>
                
            </div>
        )
    }
}
