import React from 'react';



export default class InputClient extends React.Component {
    render() {
        return (
            <div
                className={"input-element input-group " + this.props.cssClass}
                style={this.props.style}
            >
                {this.props.content.type !== 'textarea' && 
                    <input type={this.props.content.type} 
                        className="form-control" 
                        name={this.props.content.name} 
                        defaultValue={this.props.content.defaultValue} 
                        placeholder={this.props.content.placeholder}
                        pattern={this.props.content.regex ? this.props.content.regex : undefined}
                        required={this.props.content.required} />
                }
                {this.props.content.type === 'textarea' && 
                    <textarea className="form-control" 
                        name={this.props.content.name} 
                        defaultValue={this.props.content.defaultValue} 
                        placeholder={this.props.content.placeholder}
                        required={this.props.content.required} >
                    </textarea>

                }
            </div>
        )
    }
}
