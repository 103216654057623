import Mustache from 'mustache';
import { getQueryVariable } from './services';


function isObject(obj) {
  return typeof obj === 'object';
}

function isArray(obj) {
  return Array.isArray(obj);
}


function isLanguageObj(obj) {
  if (!obj) {
    return false;
  }
  if (isArray(obj)) {
    return false;
  }
  if (!isObject(obj)) {
    return false;
  }
  var hasKey = false;
  for (let key in obj) {
    hasKey = true;
    if (key.length !== 2 && key !== 'all') {
      return false;
    }
  }
  return hasKey;
}

function compile(str, context) {
  const isJson = (toCheck) => {
    try {
      return JSON.parse(toCheck);
    } catch (error) {
      return false
    }
  };

  if (typeof str === "string") {
    const mustacheReturn = Mustache.render(decodeURI(decodeURI(str)).replace(/{{([^{}]*)}}/g, a => `{${a}}`), context);
    return isJson(mustacheReturn) ? JSON.parse(mustacheReturn) : mustacheReturn;
  }

  if (isJson(str)) return isJson(str);

  if (Array.isArray(str)) return str;

  return decodeURI(str);
}

function convertToSingleLanguage(obj, language, context) {
  if ((obj[language] && !Array.isArray(obj[language])) || (Array.isArray(obj[language]) && obj[language].length) ) {
    return compile(obj[language], context);
  }

  return compile(obj.all, context);
}

function recursiveConvert(obj, language, context) {
  if (isLanguageObj(obj)) {
    return convertToSingleLanguage(obj, language, context);
  }
  if (isArray(obj)) {
    return obj.map(element => {
      if (isLanguageObj(element)) {
        return convertToSingleLanguage(element, language, context);
      }
      return recursiveConvert(element, language, context)
    });
  }
  if (isObject(obj)) {
    for (let key in obj) {
      obj[key] = recursiveConvert(obj[key], language, context);
    }
    return obj;
  }
  return obj;
}

function cleanUpPage(page, context) {
  var language = getCurrentLang(page.languages);

  var newContent = []
  for (let element of page.content) {
    let { content, config } = element;

    if (isLanguageObj(config)) {
      element.config = convertToSingleLanguage(config, language, context);
    } else {
      for (let key in config) {
        config[key] = recursiveConvert(config[key], language, context);
      }
      element.config = config;
    }

    if (isLanguageObj(content)) {
      element.content = convertToSingleLanguage(content, language, context);
    } else {
      for (let key in content) {
        content[key] = recursiveConvert(content[key], language, context);
      }
      element.content = content;
    }
    newContent.push(element);
  }
  page.content = newContent;

}

const detectLanguage = () => {
  const query = getQueryVariable('lang');
  const language = query ? query : window.navigator.userLanguage || window.navigator.language;

  const thisRegex = new RegExp('\[zhZH]{2}[-_][hHsScC][aAgGnN][nN]*[sS]*.*');

  if (language.substr(0, 2).toLowerCase() === 'zh' && !thisRegex.test(language)) { // zh-CN e zh-SG -> ZH ? CN
    return 'cn';
  }

  return language.substr(0, 2).toLowerCase();
};

export function cleanUpByLanguage(data) {
  data.vars['language'] = detectLanguage();
  let context = data.vars;
  for (let page of data.experience.pages) {
    page.content = JSON.parse(page.content || '[]');
    page.languages = JSON.parse(page.languages || '[]');
    cleanUpPage(page, context);
  }
  return data;
}

export function getCurrentLang(languages) {
  let language = detectLanguage();

  if (languages.languages.indexOf(language) === -1) {
    language = languages.defaultLanguage;
  }

  return language;
}
