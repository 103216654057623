import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import ReactPlayer from 'react-player'

const video = ({
  analytics,
  cssClass,
  galleries,
  style,
  content: {
    url,
    width,
    height,
    loop,
    controls,
    preview,
  },
}) => {
  const handleStart = () => {
    if (analytics && window.dataLayer) {
      window.dataLayer.push({ 'event': analytics });
    }
  };

  const findPreviewImage = () => {
    if (preview && preview.gallery && preview.image) {
      const { gallery, image } = preview;
      const foundGallery = galleries.find(({ uid }) => `${uid}` === `${gallery}`);
      if (foundGallery && foundGallery.items && foundGallery.items.length) {
        const foundImage = foundGallery.items.find(({ uid }) => `${uid}` === `${image}`);
        if (foundImage) {
          return foundImage.url;
        }
      }
    }
  };

  return (
    <div
      className={classnames('video-element', cssClass)}
      style={style}
    >
      <ReactPlayer
        url={url}
        width={width}
        height={height}
        loop={loop}
        controls={controls}
        onStart={handleStart}
        light={!isEmpty(preview) && findPreviewImage()}
        config={{
          file: {
            attributes: {
              autoPlay: !isEmpty(preview),
            },
          },
        }}
      />
    </div>
  )
}


export default video
