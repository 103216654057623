import React, { useEffect, useState } from 'react';
import { faHeart as faHeartFull } from '@fortawesome/free-solid-svg-icons';
import { faHeart as faHeartVoid } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { services } from '../../../services';
import 'url-search-params-polyfill';

const Whishlist = ({ gallery, wishlistName, uid }) => {
  const { wishlists = [] } = gallery;
  const [wishlisted, setWishlisted] = useState(wishlists.includes(wishlistName));

  // Check if query params wishlist == add then automatically set wishlist On
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    console.log('check gallery uid ' + uid);
    if (params.get('wishlist') == uid) {
      setWishlistedOn(wishlistName, uid);
    }
  }, [wishlistName, uid]);

  // Request to set wishlist Off
  const setWishlistedOff = async (wishlistName, uid) => {
    if (await services.deleteWishlist(wishlistName, uid)) {
      setWishlisted(false);
    }
  }

  // Request to set wishlist On
  const setWishlistedOn = async (wishlistName, uid) => {
    if (await services.postWishlist(wishlistName, uid)) {
      setWishlisted(true);
    }
  }

  const handleWishlistClick = (uid) => async (event) => {
    event.stopPropagation();

    if (wishlisted) {
      setWishlistedOff(wishlistName, uid);
    } else {
      setWishlistedOn(wishlistName, uid);
    }
  };

  return (
    <FontAwesomeIcon
      size="lg"
      icon={wishlisted ? faHeartFull : faHeartVoid}
      color="black"
      className="gallery-wishlist-icon"
      onClick={handleWishlistClick(uid)}
    />
  );
};

export default Whishlist;
