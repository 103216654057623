import React from 'react';



export default class LocationClient extends React.Component {

    componentDidMount = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(this.showPosition);
        } else {
            console.log("Error: gelocation is not available");
        }
    }

    showPosition = (position) => {
        let location = {
            location: {
                lat: position.coords.latitude,
                lon: position.coords.longitude
            }
        };
        console.log("current position:", location);
        this.props.send(this.props.content.submitUrl, location);
    }

    render() {
        return (
            <div 
                className={"location-element form-group " + this.props.cssClass}
                style={this.props.style}
            >
            </div>
        )
    }
}
