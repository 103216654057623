import React from 'react';



export default class FacebookClient extends React.Component {

    get page() {
        if (this.props.content.url) {
            return this.props.content.url;
        }
        return this.props.config.fb;
    }
    get width() {
        if (this.props.content.width) {
            return this.props.content.width;
        }
        return 500;
    }
    get height() {
        if (this.props.content.height) {
            return this.props.content.height;
        }
        return 500;
    }

    componentDidMount() {
        setTimeout(() => {
            window.FB.XFBML.parse();
        }, 0);
   }

    render() {
        return (
            <div
                className={"facebook-element " + this.props.cssClass}
                style={{ minWidth: this.width+'px', textAlign: 'center', ...this.props.style }}
            >
                <div className="fb-page" data-href={this.page} data-tabs="timeline" data-width={this.width} data-height={this.height} data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true">
                    <blockquote cite={this.page} className="fb-xfbml-parse-ignore">
                        <a href={this.page}>Facebook</a>
                    </blockquote>
                </div>                   
            </div>
        )
    }
}
