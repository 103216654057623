import React from 'react';
import isObject from 'lodash/isObject';
import { services } from '../../services';

const TextClient = ({ content, cssClass, style }) => {
  const data = {};

  if (isObject(content)) {
    Object.keys(content).forEach((contentKey) => {
      data[contentKey] = content[contentKey];
    });
  } else {
    data.textValue = content;
  }

  const getHtml = () => {
    let textValue = data.textValue;
  
    if (typeof textValue !== 'string') {
      textValue = '';
    }

    try {
      textValue = decodeURI(textValue);
    } catch (e) { }

    /**
     * Internal Page
     */
    if (data.clickBehaviour === 'pageId' && data.pageId) {
      return (
        `<a href="${services.appendUID('?_p=' + data.pageId)}">
          ${textValue}
        </a>`
      );
    }

    /**
     * External Url
     */
    if (data.clickBehaviour === 'url' && data.url) {
      const href = data.url;

      return (
        `<a
          href=${href.includes('http://') || href.includes('https://') ? href : 'http://' + href}
          target="_blank"
          rel="noopener noreferrer"
        >
          ${textValue}
        </a>`
      );
    }

    /**
     * Just text
     */
    return textValue;
  }

  return (
    <div
      className={`text-element ${cssClass}`}
      dangerouslySetInnerHTML={{ __html: getHtml() }}
      style={style}
    />
  );
}

export default TextClient;
