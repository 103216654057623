import React from 'react';
import axios from 'axios';



export default class InstagramClient extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentHtml: ''
        };
    }

    setup() {
        return new Promise((ok, fail) => {
            let url = 'https://api.instagram.com/oembed/?url='+this.props.content.url;
            if (this.props.content.maxwidth) {
                url += '&maxwidth='+this.props.content.maxwidth;
            }
            axios
            .get(url)
            .then(res => {
                return ok(res.data);
            }).catch(err => fail(err));
        });
    }

    componentDidMount = () => {
        this.setup().then(data => {
            this.setState({currentHtml: data.html});
            setTimeout(() => {
                window.instgrm.Embeds.process();
            },0);
        });
    }

    render() {
        return (
            <>
                <div
                    className={"instagram-element d-flex justify-content-center " + this.props.cssClass}
                    style={this.props.style}
                    dangerouslySetInnerHTML={{__html: this.state.currentHtml}}
                >
                </div>
            </>
        )
    }
}
