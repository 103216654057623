import React from 'react'
import PropTypes from 'prop-types'
import { services } from '../../../services';
import TitleDescription from './TitleDescription';

const Image = ({
  analytics,
  tap,
  openDetails,
  actualUrl,
  galleryId,
  categoryId,
  title,
  titlePosition,
  uid,
  description,
  wishlist,
  wishlistName,
  gallery,
}) => {
  const handleImageClick = () => {
    if (analytics && window.dataLayer) {
      window.dataLayer.push({ 'event': analytics });
    }
  };

  let toReturn = null;

  /**
   * Zoom
   */
  if (tap === true || (!tap && openDetails === true)) {
    toReturn = (
      <a
        href={actualUrl}
        data-lightbox={`gallery-${galleryId}`}
        data-title=""
        onClick={handleImageClick}
      >
        <img src={actualUrl} className="d-block w-100" alt="" />
      </a>
    );

  /**
   * Internal link
   */
  } else if ((tap || openDetails) && (!isNaN(tap) || !isNaN(openDetails))) {
    toReturn = (
      <a
        href={services.appendUID(`?_p=${tap || openDetails}&_gid=${categoryId}`)}
        onClick={handleImageClick}
      >
        <img src={actualUrl} className="d-block w-100" alt="" />
      </a>
    );

  /**
   * External link
   */
  } else if (tap || openDetails) {
    const href = tap || openDetails;

    const getEnhancedUrl = () => {
      if (
        href.includes('http://')
        || href.includes('https://')
        || href.includes('javascript:')
        || href.includes('tel:')
        || href.includes('mailto:')
      ) return href;

      return `http://${href}`;
    };

    toReturn = (
      <a
        href={getEnhancedUrl()}
        target={href.includes('javascript:') ? '_self' : '_blank'}
        rel="noopener noreferrer"
        onClick={handleImageClick}
      >
        <img src={actualUrl} className="d-block w-100" alt="" />
      </a>
    );

  /**
   * No link
   */
  } else {
    toReturn = <img src={actualUrl} className="d-block w-100" alt="" />;
  }

  return (
    <>
      {toReturn}
      {(titlePosition || (wishlist && wishlistName)) && (
        <TitleDescription
          uid={uid}
          titlePosition={titlePosition}
          title={title}
          description={description}
          gallery={gallery}
          wishlist={wishlist}
          wishlistName={wishlistName}
        />
      )}
    </>
  );
}

Image.propTypes = {

}

export default Image
