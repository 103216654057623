import React from 'react';
import $ from 'jquery';


export default class PopupClient extends React.Component {
    render() {
        var content = this.props.content.content;
        if (typeof content !== 'string') {
            content = '';
        }
        var modal = "#modal-"+this.props.id;
        setTimeout(() => {
            $(modal).modal();
        }, 100);
        return (
            <div
                className={"popup-element " + this.props.cssClass}
                style={this.props.style}
            >
                <div className="modal fade" id={"modal-"+this.props.id} tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            {!this.props.content.closeButton && <div className="modal-header">
                                <h5 className="modal-title">{this.props.content.title}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>}
                            <div className="modal-body" dangerouslySetInnerHTML={{__html: decodeURI(content)}}>
                            </div>
                            {this.props.content.closeButton && <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">
                                    {this.props.content.closeButtonTxt}
                                </button>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
