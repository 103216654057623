import TextClient from './shared/components/text';
import ScriptClient from './shared/components/script';
import GalleryClient from './shared/components/gallery';
import ImageClient from './shared/components/image';
import InputClient from './shared/components/input';
import LabelClient from './shared/components/label';
import ButtonClient from './shared/components/button';
import CheckboxClient from './shared/components/checkbox';
import SelectClient from './shared/components/select';
import RadioClient from './shared/components/radio';
import PopupClient from './shared/components/popup';
import InstagramClient from './shared/components/instagram';
import FacebookClient from './shared/components/facebook';
import PrivacyPolicyClient from './shared/components/privacy-policy';
import RedirectClient from './shared/components/redirect';
import UploadClient from './shared/components/upload';
import LocationClient from './shared/components/location';
import VideoClient from './shared/components/video';
import LanguageSelectorClient from './shared/components/language-selector';
import CountdownClient from './shared/components/countdown';
import MapClient from './shared/components/map';
import StoreLocatorClient from './shared/components/store-locator';


export const configuration = {
  api: process.env.REACT_APP_API + 'wp-json/temera/'
}

export const availableComponents = [{
  category: 'component',
  type: 'text',
  component: TextClient
}, {
  category: 'component',
  type: 'html',
  component: TextClient
}, {
  category: 'component',
  type: 'script',
  component: ScriptClient
}, {
  category: 'component',
  type: 'video',
  component: VideoClient
}, {
  category: 'component',
  type: 'image',
  component: ImageClient
}, {
  category: 'component',
  type: 'gallery',
  component: GalleryClient
}, {
  category: 'component',
  type: 'popup',
  component: PopupClient
}, {
  category: 'form',
  type: 'input',
  component: InputClient
}, {
  category: 'form',
  type: 'upload',
  component: UploadClient
}, {
  category: 'form',
  type: 'label',
  component: LabelClient
}, {
  category: 'form',
  type: 'button',
  component: ButtonClient
}, {
  category: 'component',
  type: 'link',
  component: ButtonClient
}, {
  category: 'component',
  type: 'redirect',
  component: RedirectClient
}, {
  category: 'form',
  type: 'checkbox',
  component: CheckboxClient
}, {
  category: 'form',
  type: 'select',
  component: SelectClient
}, {
  category: 'form',
  type: 'radio',
  component: RadioClient
}, {
  category: 'component',
  type: 'instagram',
  component: InstagramClient
}, {
  category: 'component',
  type: 'facebook',
  component: FacebookClient
}, {
  category: 'component',
  type: 'privacy',
  component: PrivacyPolicyClient
}, {
  category: 'component',
  type: 'location',
  component: LocationClient
}, {
  category: 'others',
  type: 'language-selector',
  component: LanguageSelectorClient
}, {
  category: 'component',
  type: 'countdown',
  component: CountdownClient
}, {
  category: 'others',
  type: 'map-store-locator',
  component: MapClient
}, {
  category: 'others',
  type: 'store-locator',
  component: StoreLocatorClient
}];

export function getComponentType(currentElement) {
  if (!currentElement) {
    return null;
  }
  for (var element of availableComponents) {
    if (element.type === currentElement.type) {
      return element;
    }
  }
  return null;
}

export function getComponent(currentElement) {
  var tmp = getComponentType(currentElement);
  if (!tmp) {
    return null;
  }
  return tmp.component;
}
