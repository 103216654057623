import React from 'react';


var loadScript = function(src) {
    var tag = document.createElement('script');
    tag.async = false;
    tag.src = src;
    document.getElementsByTagName('body')[0].appendChild(tag);
  }


export default class Analytics extends React.Component {

    constructor(props) {
        super(props);
        window.dataLayer = window.dataLayer || [];
    }

    get url() {
        return 'https://www.googletagmanager.com/gtag/js?id=' + this.props.ua;
    }

    gtag() {
        window.dataLayer.push(arguments);
    }
    componentDidMount() {
        this.gtag('js', new Date());
        this.gtag('config', this.props.ua);
        loadScript(this.url);
    }



    render() {
        return (
            <></>
        )
    }
}
