import React, { useEffect } from 'react';

const ScriptClient = ({ content = '' }) => {
    useEffect(() => {
        const script = document.createElement('script');

        script.type = 'text/javascript';
        script.async = true;
        // script.innerHTML = "const asd = {\"asd\": 'asd'}; alert(asd)";
        console.log('decodeURI(content):', decodeURI(btoa(content)))
        script.innerHTML = decodeURI(content);

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, [content]);

    return (
        <div />
    );
};

export default ScriptClient;
