import React, { useState, useEffect } from 'react';
import { services } from '../../services';

const RadioClient = ({
    content: {
        options: optionsProp,
        importCsv,
        name,
        required,
        defaultValue,
    },
    cssClass,
    style,
}) => {
    const [searchValue, setSearchValue] = useState('');
    const [options, setOptions] = useState(optionsProp);
    const [filteredOptions, setFilteredOptions] = useState(options);


    useEffect(() => {
        if (importCsv) {
            services.getStoreLocator().then(setOptions);
        }
    }, [importCsv]);

    useEffect(() => {
        if (searchValue.length < 3) {
            setFilteredOptions(options);
        } else {
            setFilteredOptions(options.filter(({ title }) => (
                title.toLowerCase().includes(searchValue.toLowerCase())
            )));
        }
    }, [options, searchValue])


    const renderSearch = () => (
        <div className="form-check">
            <input
                className="form-control"
                value={searchValue}
                onChange={({ target: { value } }) => setSearchValue(value)}
                type="search"
            />
        </div>
    );

    const renderStores = ({ description, uid }) => (
        <div className="form-check" key={uid}>
            <input
                className="form-check-input"
                type="radio"
                name="cms_store_locator"
                id={`cms_store_locator-${uid}`}
                value={uid}
                required={required}
            />
            <label className="form-check-label" htmlFor={uid}>
                <span dangerouslySetInnerHTML={{ __html: description }} />
            </label>
        </div>
    );

    const renderOptions = ({ text, value }) => (
        <div className="form-check" key={value}>
            <input className="form-check-input" type="radio"
                name={name}
                id={`${name}-${value}`}
                value={value}
                required={required}
                defaultChecked={defaultValue}
            />
            <label className="form-check-label" htmlFor={name + '-' + value}>
                {text}
            </label>
        </div>
    );


    return (
        <div
            className={`select-element form-group ${cssClass}`}
            style={style}
        >
            {importCsv && renderSearch()}
            {filteredOptions.map(importCsv ? renderStores : renderOptions)}
        </div>
    )
}

export default RadioClient;
