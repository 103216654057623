import React from 'react';
import $ from 'jquery';



export default class UploadClient extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            images: []
        };
    }

    onBtnClick = (event) => {
        event.preventDefault();
        if (this.props.content.maxFiles) {
            if (this.state.images.length >= this.props.content.maxFiles) {
                return;
            }
        }
        $("#"+this.props.id + "-upload").click();
    } 

    performUpload = (file) => {
        this.props.uploadFile(file).then(res => {
            this.setState({...this.state, images: [...this.state.images, res]}, () => this.updateInput());
        }).catch(err => console.log(err));
    }

    updateInput = () => {
        let input = $("#"+this.props.id + "-upload-item");
        let current = '@files[';
        for (let img of this.state.images) {
            current += img.key + ','
        }
        current += ']';
        current = current.replace(',]', ']');
        input.val(current);
        $("#"+this.props.id + "-upload").val(null);
    }

    removeInput = (item) => {
        let items = this.state.images;
        let index = items.indexOf(item);
        items.splice(index, 1);
        this.setState({
            ...this.state,
            images: items
        }, () => this.updateInput());
    }

    componentDidMount = () => {
        setTimeout(() => {
            $("#"+this.props.id + "-upload").on("change", (e) => {
                var files = e.currentTarget.files;
                if (files.length == 0) {
                    return;
                }
    
                if (!this.props.content.maxSize) {
                    this.performUpload(files[0]);
                    return;
                }
    
    
                let size = files[0].size / 1024;
                console.log(files[0]);
                console.log("dimensione file in kbyte: "+size);
                if (size > this.props.content.maxSize) {
                    alert('file troppo grande');
                } else {
                    this.performUpload(files[0]);
                }
            });
        }, 200);
    }

    render() {
        return (
            <div
                className={"upload-element input-group " + this.props.cssClass}
                style={this.props.style}
            >
                <input id={this.props.id + "-upload-item"} type="hidden" name={this.props.content.name} required={this.props.content.required} />

                {this.state.images.map(img => 
                        <span key={img.key} className="upload-item" style={{background: "url('data:image/png;base64,"+img.thumbnail+"')"}}>
                            <button className="remove-upload-item" onClick={() => this.removeInput(img)}></button>
                        </span>
                )}

                <button className="btn btn-primary" onClick={this.onBtnClick} >+</button>
                <input id={this.props.id + "-upload"} type="file" accept={this.props.content.type} style={{visibility: 'hidden'}} />

                { this.props.content.minFiles && 
                    <div className="upload-item-min-files-info">
                        <span className="upload-item-min-files-info-label">{this.props.content.counterLabel}</span>
                        <span className="upload-item-min-files-info-value">{this.state.images.length + '/' + this.props.content.minFiles }</span>
                        <input id={this.props.id + "-upload-item-min-files"} style={{width: '0px', height: '0px', border: 'none'}} type="text" name={this.props.content.name + 'min-files'} required={this.state.images.length < this.props.content.minFiles } /> 
                    </div>
                }
            </div>
        )
    }
}
