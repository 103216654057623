import React from 'react'
import classnames from 'classnames';
import { services } from '../../services';

const Image = ({
  id,
  analytics,
  cssClass,
  galleries,
  style,
  content: {
    currentGallery,
    image,
    clickBehaviour,
    url,
    pageId,
    titlePosition,
    titleValue,
  },
}) => {
  const gallery = galleries.find(({ uid }) => `${uid}` === `${currentGallery}`)

  if (!gallery) return null;

  const imageObject = image && image !== 'null'
    ? gallery.items.find(({ uid }) => +uid === +image) || {}
    : (gallery.items ? gallery.items[0] : {});
  
  const handleImageClick = () => {
    if (analytics && window.dataLayer) {
      window.dataLayer.push({ 'event': analytics });
    }
  };
  
  const renderImage = () => {
    if (clickBehaviour === 'zoom') {
      return (
        <a
          href={imageObject.url}
          data-lightbox={`gallery-${id}`}
          data-title=""
          onClick={handleImageClick}
        >
          <img src={imageObject.url} className="d-block w-100" alt="" />
        </a>
      );
    }

    if (url) {
      const getEnhancedUrl = () => {
        if (
          url.includes('http://')
          || url.includes('https://')
          || url.includes('javascript:')
        ) return url;

        return `http://${url}`;
      };

      return (
        <a
          href={getEnhancedUrl()}
          target={url.includes('javascript:') ? '_self' : '_blank'}
          rel="noopener noreferrer"
          onClick={handleImageClick}
        >
          <img src={imageObject.url} className="d-block w-100" alt="" />
        </a>
      );
    }

    if (pageId) {
      return (
        <a
          href={services.appendUID("?_p=" + pageId)}
          onClick={handleImageClick}
        >
          <img src={imageObject.url} className="d-block w-100" alt="" />
        </a>
      );
    }

    return <img src={imageObject.url} className="d-block w-100" alt="" />;
  };

  return (
    <div
      className={classnames('image-element', cssClass)}
      style={style}
    >
      <div
        id={`gallery-${id}`}
        className="carousel slide"
        data-ride="carousel"
      >
        <div className="carousel-inner">
          <div className="carousel-item active">
            {renderImage()}
            {titlePosition && (
              <div className={classnames('image-title', { [titlePosition]: true })}>
                <h3>{titleValue}</h3>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

Image.propTypes = {

}

export default Image
