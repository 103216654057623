import React from 'react';
import { connect } from 'react-redux';
import {
    parse,
    isValid,
    isEqual,
    isAfter,
    isBefore,
} from 'date-fns';
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { services } from './services';
import { getCurrentLang } from './language';
import { getExperience } from './redux/selectors';
import { updateExperience } from './redux/actions';
import { getComponent } from './config';
import Analytics from './analytics';
import $ from 'jquery';
import 'url-search-params-polyfill';

class Pages extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            isSubmitting: false,
        };
        window.setCustomVar = (name, value) => {
            this._customVars[name] = value;
            this.setState({...this.state});
        }
    }

    _customVars = {};

    get vars() {
        let _vars = new URLSearchParams(window.location.search);
        let vars = {}
        for (let key of _vars.keys()) {
            vars[key] = _vars.get(key);
        }
        if (!this.props.experience) {
            return vars;
        }
        for (let key in this.props.experience.vars) {
            vars[key] = this.props.experience.vars[key];
        }
        for (let key in this._customVars) {
            vars[key] = this._customVars[key];
        }
        return vars;
    }

    get token() {
        return this.props.experience.csrf_token;
    }

    get currentPage() {
        if (!this.props.experience) {
            return {};
        }
        let pageId = services.pageId;
        if (pageId) {
            for (let page of this.props.experience.experience.pages) {
                if (page.uid === Number(pageId) || page.slug === pageId) {
                    return page;
                }
            }
        }
        return this.props.experience.experience.pages[0];
    }

    get components() {
        return this.currentPage.content || [];
    }

    get languages() {
        return this.currentPage.languages || [];
    }

    get currentLanguage() {
        return getCurrentLang(this.languages);
    }

    get galleries() {
        return this.props.experience.experience.galleries;
    }

    get config() {
        if (!this.props.experience) {
            return {};
        }
        return this.props.experience.experience.configuration;
    }

    get pages() {
        if (!this.props.experience) {
            return {};
        }
        return this.props.experience.pages;
    }

    componentDidMount = () => {
        if (!this.props.experience) {
            this.setState({...this.state, isLoading: true});
            services.setup().then(data => {
                this.setState({
                    ...this.state,
                    recaptcha: data.experience.configuration.recaptcha,
                    isLoading: false,
                });
                this.props.updateExperience(data);
            });
        }

    }

    executeRule = (rule) => {
        const normalize = (value) => value === undefined || value === null || value === '' ? '' : value;

        const currentValue = normalize(this.vars[rule.variable]);
        let againstTo = normalize(rule.value);
        const withTime = parse(`${againstTo} +00`, 'dd/MM/yyyy HH:mm x', new Date());
        const noTime = parse(`${againstTo} +00`, 'dd/MM/yyyy x', new Date());

        if (isValid(withTime) || isValid(noTime)) {
            const date = isValid(withTime) ? withTime : noTime;
            const currentDate = new Date(currentValue * 1000);

            switch (rule.operator) {
                case '==': return isEqual(currentDate, date);
                case '!=': return !isEqual(currentDate, date);
                case 'greater': return isAfter(currentDate, date);
                case 'greaterEqual': return isAfter(currentDate, date) || isEqual(currentDate, date);
                case 'lower': return isBefore(currentDate, date);
                case 'lowerEqual': return isBefore(currentDate, date) || isEqual(currentDate, date);
                default: return false;
            }
        } else {
            if (!isNaN(currentValue) && currentValue.length > 0) {
                againstTo = Number(againstTo);
            }
            switch (rule.operator) {
                case '==': return currentValue === againstTo;
                case '!=': return currentValue !== againstTo;
                case 'greater': return currentValue > againstTo;
                case 'greaterEqual': return currentValue >= againstTo;
                case 'lower': return currentValue > againstTo;
                case 'lowerEqual': return currentValue >= againstTo;
                default: return true;
            }
        }
    }

    isVisible = (component) => {
        let config = component.config || {};
        let rules = config.visibility || [];
        for (let rule of rules) {
            if (!this.executeRule(rule)) {
                return false;
            }
        }
        return true;
    }

    saveCurrentBody = (body) => {
        let toBeSaved = {
            body: body,
            date: new Date().getTime()
        }
        localStorage.setItem("formBody", JSON.stringify(toBeSaved));
    }

    loadCurrentBody = () => {
        const thresholdTime = 1000 * 60 * 5 // 5 min

        let body = {};
        let savedElements = JSON.parse(localStorage.getItem("formBody"));
        if (savedElements && (new Date().getTime() - savedElements.date) < thresholdTime) {
            for (var prop in savedElements.body) {
                body[prop] = savedElements.body[prop];
            }
        }
        localStorage.removeItem("formBody");
        return body;
    }

    uploadFile = (file) => {
        return services.uploadFile(this.token, file);
    }


    send = (formType, data) => {
        return services.send(formType, this.token, data);
    }

    setNextStep = ({ submitUrl, successUrl, errorUrl, nextStep, nextStepUrl, externalUrl }) => {
        this.setState({ submitUrl, successUrl, errorUrl, nextStep, nextStepUrl, externalUrl });
    }

    recaptchable = () => !!this.components.find(({ content }) => content && !content.nextStep && content.submit)

    isActionUrl = (url) => url && url.charAt(0) === '_'

    handleActionUrl = (url) => {
        if (url === '_back') {
            window.history.back();
            return
        }
    }

    handleSubmit = (event) => {
        event.preventDefault();

        if (this.state.isSubmitting) {
            return;
        }
        this.setState({...this.state, isSubmitting: true});
        window.setCustomVar('_is_submitting_lead', 'true');

        let {
            submitUrl,
            successUrl,
            errorUrl,
            nextStep,
            nextStepUrl,
            externalUrl,
            recaptchaToken,
        } = this.state;
        let body = this.loadCurrentBody();

        for (var element of $(event.target).find('input, select, textarea')) {
            let name = $(element).attr("name");
            if (!name) {
                continue;
            }

            const elementType = $(element).attr('type');

            if (elementType === 'checkbox' || elementType === 'radio') {
                if ($(element).is(":checked")) {
                    body[name] = element.value;
                }
            } else {
                body[name] = element.value;
            }
        }

        if (recaptchaToken) {
            body._recaptcha = recaptchaToken;
        }

        let tmp = window.location.href;
        if (externalUrl) {
            this.saveCurrentBody(body);
            const enhancedUrl = Object.keys(body).reduce((accumulator, key) => (
                services.appendQuery(accumulator, `${key}=${body[key]}`)
            ), externalUrl);
            this.setState({...this.state, isSubmitting: false});
            window.setCustomVar('_is_submitting_lead', 'false');
            const parser = new DOMParser();
            window.location = parser.parseFromString(
                `<!doctype html><body>${enhancedUrl}`,
                'text/html'
            ).body.textContent;
            return;
        } else if (nextStep) {
            this.saveCurrentBody(body);
            tmp = services.appendQuery(tmp, '_p='+nextStepUrl);
            tmp = services.appendUID(tmp);
            this.setState({...this.state, isSubmitting: false});
            window.setCustomVar('_is_submitting_lead', 'false');
            window.location = tmp;
            return;
        } else {
            tmp = services.appendQuery(tmp, '_p='+successUrl);
        }

        const enhancedSuccessUrl = services.appendUID(tmp);

        services.send(submitUrl, this.token, body).then(response => {
            this.saveCurrentBody(response);
            this.setState({...this.state, isSubmitting: false});
            window.setCustomVar('_is_submitting_lead', 'false');
            if (this.isActionUrl(successUrl)) {
                this.handleActionUrl(successUrl)
            } else {
                window.location = enhancedSuccessUrl;
            }
        }).catch(err => {
            console.log(err);
            this.setState({...this.state, isSubmitting: false});
            window.setCustomVar('_is_submitting_lead', 'false');
            if (errorUrl) {
                this.saveCurrentBody(body);
                if (this.isActionUrl(errorUrl)) {
                    this.handleActionUrl(errorUrl)
                } else {
                    let tmp = window.location.href;
                    tmp = services.appendQuery(tmp, '_p='+errorUrl);
                    let finalUrl = services.appendUID(tmp) + '&_error=true';
                    window.location = finalUrl;
                }
            }
        });
    }

    getContainerStyle = ({ background } = {}) => {
        const style = {};

        if (background) {
            style.backgroundRepeat = 'no-repeat';
            style.backgroundPosition = 'center';
            const { image, gallery, size } = background;

            if (image && gallery) {
                const foundGallery = this.galleries.find(({ uid }) => `${uid}` === `${gallery}`);
                if (foundGallery && foundGallery.items && foundGallery.items.length) {
                    const foundImage = foundGallery.items.find(({ uid }) => `${uid}` === `${image}`);
                    if (foundImage) {
                        style.backgroundImage = `url(${foundImage.url})`;
                    }
                }
            }

            if (size) {
                switch(size) {
                    case 'cover':
                    case 'contain':
                        style.backgroundSize = size;
                        break;

                    case 'height':
                        style.backgroundSize = 'auto 100%';
                        break;

                    case 'width':
                        style.backgroundSize = '100% auto';
                        break;

                    default: break;
                }
            }
        }

        return style;
    };

    render() {
        if (this.state.isLoading) {
            return (
                <>
                    <div className="outerLoader">
                        <div className="loader">Loading...</div>
                    </div>
                </>
            );

        }

        return (
            <>
                <div className="container" style={{ textAlign: 'center' }}>
                    <form onSubmit={this.handleSubmit}>
                        {this.components.filter(c => this.isVisible(c)).map((component, index) => {
                            var Component = getComponent(component);
                            if (Component === null) {
                                return '';
                            }
                            var cssClass = (component.config || {}).cssClass || '';
                            return (
                                <Component
                                    key={component.id}
                                    id={component.id}
                                    cssClass={cssClass}
                                    content={component.content || {}}
                                    galleries={this.galleries}
                                    config={this.config}
                                    analytics={(component.config || {}).analyticsTag}
                                    pages={this.pages}
                                    uploadFile={this.uploadFile}
                                    send={this.send}
                                    setNextStep={this.setNextStep}
                                    languages={this.languages}
                                    currentLanguage={this.currentLanguage}
                                    vars={this.vars}
                                    style={this.getContainerStyle(component.config)}
                                />
                            )
                        })}
                        {this.recaptchable() && (
                            <GoogleReCaptchaProvider reCaptchaKey={this.state.recaptcha}>
                                <GoogleReCaptcha onVerify={token => this.setState({ recaptchaToken: token })} />
                            </GoogleReCaptchaProvider>
                        )}
                    </form>
                </div>
                {this.config.ua && <Analytics ua={this.config.ua} />}
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        experience: getExperience(state),
    };
}


export default connect(mapStateToProps, { updateExperience })(Pages);
