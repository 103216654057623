import React from 'react'
import classnames from 'classnames';
import Whishlist from './Whishlist';

const TitleDescription = ({
  uid,
  titlePosition,
  title,
  description,
  gallery,
  wishlist,
  wishlistName,
}) => {
  const renderWishlistHeart = (uid) => {
    if (!wishlist || !wishlistName) return null;

    return (
      <Whishlist
        gallery={gallery}
        wishlistName={wishlistName}
        uid={uid}
      />
    );
  };

  return (
    <div>
      <div className={classnames('gallery-title', `uid-${uid}`, titlePosition)}>
        <h3>{title}</h3>
        {titlePosition === 'outside' && renderWishlistHeart(uid)}
      </div>
      {titlePosition !== 'outside' && (
        <div className="wishlist-icon">
          {renderWishlistHeart(uid)}
        </div>
      )}
      <div className={classnames('gallery-description', `uid-${uid}`)}>
        <span dangerouslySetInnerHTML={{ __html: description }} />
      </div>
    </div>
  );
};

TitleDescription.propTypes = {

}

export default TitleDescription
