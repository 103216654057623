import React from 'react';



export default class LabelClient extends React.Component {
    render() {
        return (
            <div
                className={"label-element " + this.props.cssClass}
                style={this.props.style}
            >
                <label htmlFor={this.props.content.for}>{this.props.content.text}</label>
            </div>
        )
    }
}
