import axios from 'axios';
import {configuration} from './config'

export function getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    for (var i = vars.length - 1; i >= 0; i--) {
        var pair = vars[i].split('=');
        if (decodeURIComponent(pair[0]) === variable) {
            return decodeURIComponent(pair[1]);
        }
    }
    return null;
}

export function parseQueryVariables(path) {
    var map = {};
    var query = path || window.location.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        map[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
    }
    return map;
}

class Services {

    currentExperience;

    constructor() {
        let tmp = window.location.pathname;
        if (tmp.endsWith('/')) {
            tmp = tmp.substring(0, tmp.length - 1);
        }
        let path = tmp.split('/');
        if (path.length > 2) {
            this.experienceId = path[path.length - 2];
            this.pageId = path[path.length -1];
            if (this.experienceId === 'experience') {
                this.experienceId = path[path.length - 1];
            }
        } else if (path.length > 1) {
            this.experienceId = path[path.length - 1];
        }
        let pageId = getQueryVariable('_p');
        if (pageId) {
            this.pageId = pageId;
        }
        document.body.className = "exp"+this.pageId;
    }

    enhanceHeaderWithNonce(headers) {
        if (window._temeracms && window._temeracms.token) {
            headers['X-WP-Nonce'] = window._temeracms.token
        }

        return headers;
    }


    appendQuery(url, value) {
        if (url.indexOf('?') !== -1) {
            url += '&' + value;
        } else {
            url += '?' + value;
        }
        return url;
    }

    appendUID(url) {
        let index = url.indexOf('?');
        let cleaned, variables;
        if (index != -1) {
            cleaned = url.substring(0, index);
            variables = parseQueryVariables(url.substring(index+1));
        } else {
            cleaned = url;
            variables = {};
        }
        let queries = {...parseQueryVariables(), ...variables};
        cleaned += '?';
        for (let key in queries) {
            cleaned += key + '=' + queries[key] + '&';
        }
        return cleaned.substring(0, cleaned.length-1);
    }
   
    setup() {
        const uid = getQueryVariable('uid');
        const gid = getQueryVariable('_gid');
        const lang = getQueryVariable('lang');
        const url = configuration.api + 'v1/setup/' + this.experienceId + (this.pageId ? '/' + this.pageId : '');
        const headers = {};

        return new Promise((ok, fail) => {
            axios
            .get(
                url,
                {
                    params: { uid, lang, _gid: gid },
                    headers: this.enhanceHeaderWithNonce(headers),
                },
            )
            .then(res => {
                this.currentExperience = res.data;
                return ok(res.data);
            }).catch(err => fail(err));
        });
    }

    get privateVariables() {
        let v = {};
        let queryVariables = parseQueryVariables();
        for (let key in queryVariables) {
            v['_'+key] = queryVariables[key];
        }
        for (let key in this.currentExperience.vars) {
            if (key.startsWith('_')) {
                v[key] = this.currentExperience.vars[key];
            }
        }
        return v;
    }

    send(formType, token, data) {
        data['_experience_id'] = this.experienceId;
        data['_uid'] = getQueryVariable('uid');
        let all = {...this.privateVariables, ...data};
        return new Promise((ok, fail) => {
            const headers = { 'X-CSRF-TOKEN': token };

            axios
            .post(configuration.api + 'v1/collectors/'+formType+'/leads', all, {
                headers: this.enhanceHeaderWithNonce(headers),
            })
            .then(res => {
                return ok(res.data);
            }).catch(err => fail(err));
        });
    }

    uploadFile(token, file) {
        return new Promise((ok, fail) => {
            let formData = new FormData();
            formData.append('file', file);

            const headers = {
                'X-CSRF-TOKEN': token,
                'content-type': 'multipart/form-data',
            };

            axios.post(configuration.api +'v1/experiences/'+this.experienceId+'/files', formData, {
                headers: this.enhanceHeaderWithNonce(headers),
            })
            .then(res => {
                return ok(res.data);
            }).catch(err => fail(err));
            //ok({key: "5e709293ee2cf4.77758278", thumbnail: "iVBORw0KGgoAAAANSUhEUgAAAMgAAAB3CAYAAABGxA8+AAAMQW…0ZW1lcmEvdG1wL3BocHAwc3VVVwwV+30AAAAASUVORK5CYII="});
        });
    }

    getStoreLocator(filterBy) {
        return new Promise((ok, fail) => {
            axios({
                method: 'get',
                url: `${configuration.api}v1/stores`,
                params: { 
                    lang: getQueryVariable('lang'),
                    q: filterBy
                }
            })
            .then(res => {
                return ok(res.data);
            }).catch(err => fail(err));
        });
    }

    postWishlist(wishlistName, mediaId) {
        const headers = {};

        return axios({
            method: 'post',
            url: `${configuration.api}v1/wishlist/${wishlistName}/${mediaId}`,
            headers: this.enhanceHeaderWithNonce(headers),
        })
    }

    deleteWishlist(wishlistName, mediaId) {
        const headers = {};

        return axios({
            method: 'delete',
            url: `${configuration.api}v1/wishlist/${wishlistName}/${mediaId}`,
            headers: this.enhanceHeaderWithNonce(headers),
        })
    }
}

export const services = new Services()
