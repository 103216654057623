import React from 'react';



export default class LanguageSelectorClient extends React.Component {

    onChange = (lang) => {
        let location = window.location.href;
        if (location.indexOf('lang=') === -1) {
            let adder = '';
            if (location.indexOf('?') !== -1) {
                adder = '&';
            } else {
                adder = '?';
            }
            location += adder + 'lang=' + lang;
        } else {
            let regex = /lang=[a-zA-Z0-0]+/
            location = location.replace(regex, 'lang=' + lang);
        }

        window.location.href = location;
    } 

    render() {
        return (
            <div
                className={"language-selector-element " + this.props.cssClass}
                style={this.props.style}
            >
                <div className="dropdown">
                    <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {this.props.currentLanguage}
                    </button>
                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        {this.props.languages.languages.filter(f => f !== this.props.currentLanguage).map(l => <a key={l} className={"dropdown-item lang-"+l} href="#" onClick={() => this.onChange(l)}>{l}</a>)}
                    </div>
                </div>
            </div>
        )
    }
}
