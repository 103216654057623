/*eslint eqeqeq: 0*/
import React from 'react';
import classnames from 'classnames';
import ReactPlayer from 'react-player'
import $ from "jquery";
import Image from './Image';
import TitleDescription from './TitleDescription';

export default class GalleryClient extends React.Component {
  get isCarousel() {
    if (!this.props.content.render) return true;
    return this.props.content.render === 'carousel';
  }
  
  get gallery() {
    for (let gallery of this.props.galleries) {
      if (gallery.uid == this.props.content.currentGallery) {
        return gallery;
      }
    }

    return { items: [] };
  }

  foundGallery = (elementUid) => (
    this.gallery.items && this.gallery.items.find(({ uid }) => `${uid}` === `${elementUid}`)
  ) || {}

  get filteredElements() {
    const { gallery, props: { content, vars } } = this;
    const { items, itemsOptions } = content;

    const findFromGalleryByUid = (byUid) => gallery.items.find(({ uid }) => uid === byUid);

    const mapOutsourceElement = ({ type, val }) => ({
      mimeType: type.split('-')[1],
      thumb: val,
      uid: val,
      url: val,
    });

    const mapItemsOptions = () => itemsOptions.map((itemOptions) => ({
      options: itemOptions,
      ...itemsOptions.type && itemOptions.type.includes('gallery')
        ? findFromGalleryByUid(itemOptions.val)
        : mapOutsourceElement(itemOptions),
    }));

    const mapItems = () => {
      if (items && (Array.isArray(items) && items.length)) {
        return items
          .filter(findFromGalleryByUid)
          .map(findFromGalleryByUid)
      }

      if (Number.isInteger(items)) return [findFromGalleryByUid(items)];
      
      return gallery.items
    };

    return itemsOptions && itemsOptions.length ? mapItemsOptions() : mapItems();
  }

  renderImage = (element, galleryId) => {
    const { options: {
      tap,
      titlePosition,
      title,
      description,
    } = {}, uid } = element;
    const { content: { openDetails, wishlist, wishlistName }, analytics } = this.props;
    const { url, categories = [] } = this.foundGallery(element.uid);
    const { id: categoryId } = categories[0] || {};

    const actualUrl = isNaN(element.url) ? element.url : url;

    return (
      <Image
        analytics={analytics}
        tap={tap}
        openDetails={openDetails}
        actualUrl={actualUrl}
        galleryId={galleryId}
        categoryId={categoryId}
        title={title}
        titlePosition={titlePosition}
        uid={uid}
        description={description}
        wishlist={wishlist}
        wishlistName={wishlistName}
        gallery={this.foundGallery(uid)}
      />
    );
  }

  renderVideo = ({ uid, options: {
    titlePosition,
    title,
    description,
  } }) => {
    const { content: { wishlist, wishlistName } } = this.props;

    return (
      <>
        <ReactPlayer
          url={this.foundGallery(uid).url}
          width="100%"
          controls
        />
        <TitleDescription
          uid={uid}
          titlePosition={titlePosition}
          title={title}
          description={description}
          gallery={this.foundGallery(uid)}
          wishlist={wishlist}
          wishlistName={wishlistName}
        />
      </>
    );
  }

  render() {
    setTimeout(() => { $('.carousel').carousel(); }, 100);

    const {
      content: { autoplay, hasIndicators, render: viewMode },
      cssClass,
      id,
    } = this.props;

    return (
      <div
        className={classnames('gallery-element', cssClass)}
        style={this.props.style}
      >
        {viewMode === 'carousel' &&
          <div
            id={`gallery-${id}`}
            className="carousel slide"
            data-ride="carousel"
            data-interval={autoplay ? "5000" : "false"}
          >
            {hasIndicators && (
              <>
                <ol className="carousel-indicators">
                  {this.filteredElements.map((_, index) => (
                    <li
                      key={index}
                      data-target={`#gallery-${id}`}
                      data-slide-to={index}
                      className={classnames(['carousel-indicator', { active: index === 0 }])}
                    >
                      <div className="indicator" />
                    </li>
                  ))}
                </ol>
              </>
            )}

            <div className="carousel-inner">
              {this.filteredElements.map((element = {}, index) => (
                <div
                  key={`${element.uid}-${index}`}
                  className={classnames('carousel-item', { active: index === 0 })}
                >
                  {element.mimeType?.includes('video')
                    ? this.renderVideo(element)
                    : this.renderImage(element, id)
                  }
                </div>
              ))}
            </div>
          </div>
        }

        {viewMode === 'grid' && (
          <div className="row">
            {this.filteredElements.map((element, index) => (
              <div className="col-6 col-md-3" key={`${element.uid}-${index}`}>
                {element.mimeType && element.mimeType.includes('video')
                  ? this.renderVideo(element)
                  : this.renderImage(element, id)
                }
              </div>
            ))}
          </div>
        )}

        {viewMode === 'scroller' && (
          <div className="scroller">
            {this.filteredElements.map((element, index) => (
              <div className="scroll-item" key={`${element.uid}-${index}`}>
                {element.mimeType && element.mimeType.includes('video')
                  ? this.renderVideo(element)
                  : this.renderImage(element, id)
                }
              </div>
            ))}
          </div>
        )}
      </div>
    )
  }
}
