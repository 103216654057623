import React from 'react';

function adjustForTimezone(date) {
    var timeOffsetInMS = date.getTimezoneOffset() * 60000;
    date.setTime(date.getTime() - timeOffsetInMS);
    return date
}


export default class CountdownClient extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            days: 0
        };
    }

    get targetDate() {
        if (this.props.content.date) {
            let d = new Date(this.props.content.date);
            d = adjustForTimezone(d);
            return d;
        }
        return new Date();
    }

    calculateDiff = () => {
        let now = new Date();
        let target = this.targetDate;
        
        let diff = target.getTime() - now.getTime(); 

        if (diff < 0) {
            this.setState({
                ...this.state,
                days: 0,
                hours: 0,
                minutes: 0,
                seconds: 0
            });
            return;
        }

        let _days = diff / (1000 * 3600 * 24); 
        let days = Math.floor(_days);

        let _hours = (_days - days) * 24;
        let hours = Math.floor(_hours);

        let _minutes = (_hours - hours) * 60;
        let minutes = Math.floor(_minutes);

        let _seconds = (_minutes - minutes) * 60;
        let seconds = Math.floor(_seconds);

        this.setState({
            ...this.state,
            days: days,
            hours: hours,
            minutes: minutes,
            seconds: seconds
        });

        setTimeout(() => this.calculateDiff(), 1000);
    }

    componentDidMount = () => {
        this.calculateDiff();
    }

    render() {
        return (
            <div
                className={"countdown-selector-element " + this.props.cssClass}
                style={this.props.style}
            >
                <span className="days">{this.state.days}</span><span className="days-label">{this.props.content.daysLabel}</span>
                &nbsp;<span className="hours">{this.state.hours}</span><span className="hours-label">{this.props.content.hoursLabel}</span>
                &nbsp;<span className="minutes">{this.state.minutes}</span><span className="minutes-label">{this.props.content.minutesLabel}</span>
                &nbsp;<span className="seconds">{this.state.seconds}</span><span className="seconds-label">{this.props.content.secondsLabel}</span>
            </div>
        )
    }
}
