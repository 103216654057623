import React from 'react';
import { services } from '../../services';
import $ from 'jquery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';

/*
address: "Via vai 12/23"
categories: [{…}]
city: "Genova"
description: "Quando la passione diventa un mestiere. Nel 1974, il fondatore Osvaldo Menegazzi inaugura un luogo unico, costellato da vecchie carte da gioco e tarocchi numerati e in edizione limitata. Questo tempio eclettico, ora in Porta Ticinese, combina tutti gli ingredienti segreti di un viaggio misterioso."
images: ["https://temera.appfactory.it/wp-content/uploads/2021/02/Il-Meneghello.png"]
lat: 45.45729
lon: 9.18103
nation: "Italia"
title: "Il Meneghello Shop"
uid: "26703"
zip: null
*/

function debounce(func, wait, immediate) {
	var timeout;
	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};


export default class StoreLocatorClient extends React.Component {

    noResults = '';

    constructor(props) {
        super(props);
        this.state = {
            stores: [],
            currentSearch: '',
            currentStore: null,
            isLoading: false
        };
        if (props.content.noResults) {
            if (typeof props.content.noResults === 'object' && props.content.noResults !== null) {
                this.noResults = '';
            } else {
                this.noResults = props.content.noResults;
            }
        }
    }


    
    handleChangeText = (x) => {
        this.setState({...this.state, currentSearch: x.target.value, isLoading: true}, this.updateVars);
        debounce(() => {
            this.updateResults();
        }, 300)();
    }


    updateResults = () => {
        if (!this.state.currentSearch) {
            this.setState({...this.state, stores: []}, this.updateVars);
            return;
        }
        services.getStoreLocator(this.state.currentSearch).then((values) => {
            this.setState({...this.state, stores: values, isLoading: false}, this.updateVars);            
        }).catch(err => {
            console.log(err);
            this.setState({...this.state, stores: [], isLoading: false}, this.updateVars);
        });
    }


    getAddress = (p) => {
        return (p.address || '') + ' ' + (p.city || '') + ' ' + (p.nation || '').trim();
    }

    selectStore = (s) => {
        this.setState({...this.state, currentStore: s, currentSearch: '', stores: []}, this.updateVars);
    }

    removeCurrentStore = () => {
        this.setState({...this.state, currentStore: null}, this.updateVars);
    }


    updateVars = () => {
        if (this.state.isLoading) {
            return;
        }
        if (this.state.stores.length == 0 && this.state.isLoading == false && this.state.currentSearch && !this.state.currentStore) {
            window.setCustomVar('_store_not_found', 'true');
        } else {
            window.setCustomVar('_store_not_found', 'false');
        }
    }




    renderStoreElement = (s) => {
        return (<div>
            <div className="element-title">{s.title}</div>
            <div className="element-address">{this.getAddress(s)}</div>
        </div>);
    }

    render() {
        return (
            <div
                className={"store-locator-element " + this.props.cssClass}
                style={this.props.style}
            >
                {!this.state.currentStore && <div className="input-group mb-2">
                    <input type="text" 
                        className="form-control" 
                        id="store-locator-input" 
                        placeholder={this.props.content.searchPlaceholder} 
                        autoComplete="off"
                        value={this.state.currentSearch} 
                        onChange={this.handleChangeText} />
                    <div className="input-group-prepend">
                        <div className="input-group-text">
                            <FontAwesomeIcon
                                size="lg"
                                icon={faSearch}
                                color="black"
                                className="gallery-wishlist-icon"
                            />
                        </div>
                    </div>
                </div>}
                {this.state.currentStore && <div class="input-group mb-2">
                    <input type="hidden" name={this.props.content.name} value={this.state.currentStore.uid} />
                    <span type="text" 
                        className="form-control">
                        {this.renderStoreElement(this.state.currentStore)}
                    </span>
                    <div className="input-group-prepend">
                        <div className="input-group-text">
                            <FontAwesomeIcon
                                size="lg"
                                icon={faTimes}
                                color="black"
                                className="gallery-wishlist-icon"
                                onClick={this.removeCurrentStore}
                            />
                        </div>
                    </div>
                </div>}
                <div className="results-container">
                    {!this.state.currentStore && this.state.stores && this.state.stores.length > 0 &&  <div className="results">
                        <ul>
                            {this.state.stores.map(s => 
                                <li key={s.uid}>
                                    <a onClick={() => this.selectStore(s)}>
                                        {this.renderStoreElement(s)}
                                    </a>
                                </li>
                            )}
                        </ul>
                    </div>}
                </div>

                {!this.state.currentStore && this.state.stores.length == 0 && this.state.currentSearch && !this.state.isLoading && <div className="no-results">

                    {this.noResults}
                </div>}

                
            </div>
        )
    }
}
