import React from 'react';



export default class SelectClient extends React.Component {
    render() {
        return (
            <div
                className={"select-element form-group " + this.props.cssClass}
                style={this.props.style}
            >
                <select className="form-control" name={this.props.content.name} defaultValue={this.props.content.defaultValue} required={this.props.content.required}>
                    {this.props.content.options && this.props.content.options.map(option => 
                        <option key={option.value} value={option.value}>{option.text}</option>
                    )}
                </select>
            </div>
        )
    }
}
