import React from 'react';
import { services } from '../../services';

export default class ButtonClient extends React.Component {

    get generateUrl() {
        if (this.props.content.external) {
            return this.props.content.externalLink
        }
        if ((this.props.content.link || '').indexOf('global-') === 0) {
            let bb = this.props.content.link.substring('global-'.length);
            for (let page of this.props.pages) {
                if (page.id === Number(bb)) {
                    return decodeURI(services.appendUID(page.permalink));
                }
            }
        }
        if ((this.props.content.link || '').indexOf('exp-') === 0) {
            let bb = this.props.content.link.substring('exp-'.length);
            return services.appendUID('/experience/' + bb);
        }
        if ((this.props.content.link || '').includes('javascript:')) {
            return services.appendUID('/experience/' + this.props.content.link);
        }
        return services.appendUID("?_p=" + this.props.content.link);
    }

    handleClick = () => {
        const {
            setNextStep,
            content: {
                submitUrl,
                nextStep,
                nextStepUrl,
                successUrl,
                errorUrl,
                externalUrl,
            },
        } = this.props;

        if (nextStep) {
            setNextStep({ submitUrl, nextStep, nextStepUrl, externalUrl });
        } else {
            setNextStep({ submitUrl, successUrl, errorUrl });
        }

        if (this.props.analytics && window.dataLayer) {
            window.dataLayer.push({ 'event': this.props.analytics });
        }
    }

    render() {
        let isSubmitting = this.props.vars['_is_submitting_lead'] || "false";
        return (
            <div
                className={"button-element " + this.props.cssClass}
                style={this.props.style}
            >
                {!this.props.content.submit &&
                    <a href={this.generateUrl} onClick={this.handleClick} disabled={isSubmitting == "true"} className="btn btn-primary">{this.props.content.text}</a>
                }

                {this.props.content.submit &&
                    <>
                        <button className="btn btn-primary" type="submit" disabled={isSubmitting == "true"} onClick={this.handleClick}>{this.props.content.text}</button>
                    </>
                }
            </div>
        )
    }
}
